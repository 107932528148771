import LandingPage from './LandingPage';
import NotFoundPage from './NotFoundPage';
import AdminPanelPage from './AdminPanelPage';
import WelcomePage from './WelcomePage';
import AnonimousPage from './AnonimousPage';
import ObserverPage from './ObserverPage';
import AuctionPage from './AuctionPage';
import ParametersPage from '../pages/ParametersPage';
import SaleOptionsPage from '../pages/SaleOptions';
import BarcodeSearchPage from './BarcodeSearchPage';
import ControlPanelPage from './ControlPanelPage';
import ProfilePage from '../pages/Profile';
import SalesManagementPage from '../pages/SalesManagementPage';
import SoldCartsPage from '../pages/SoldCartsPage';
import OrderCompletionPage from '../pages/OrderCompletionPage';
import CartPerUserPage from '../pages/CartsPerUserPage';
import MantenedoresPage from '../pages/Mantenedores';
import StockLoadAdminPage from '../pages/StockLoadAdminPage';
import LotStockAdminPage from '../pages/LotStockAdminPage';
import AuctionAdminPage from './AuctionAdminPage';
import HomeControlPanelPage from './HomeControlPanel';
import PriceListAdminPage from './PriceListAdminPage';
import SkuAdminPage from './SkuAdminPage';

const Pages = {
  LandingPage,
  NotFoundPage,
  AdminPanelPage,
  WelcomePage,
  ObserverPage,
  AnonimousPage,
  ParametersPage,
  AuctionPage,
  BarcodeSearchPage,
  SaleOptionsPage,
  ControlPanelPage,
  ProfilePage,
  SalesManagementPage,
  AuctionAdminPage,
  SoldCartsPage,
  CartPerUserPage,
  MantenedoresPage,
  StockLoadAdminPage,
  LotStockAdminPage,
  HomeControlPanelPage,
  OrderCompletionPage,
  PriceListAdminPage,
  SkuAdminPage
};

export default Pages;
