import { ImageFormats, SimpleObject } from '../types';

export enum FieldTypes {
  Text = 'text',
  Email = 'email',
  Rut = 'rut',
  Password = 'password',
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'dateTime',
  Reference = 'reference',
  Photo = 'photo',
  MultiReference = 'multiReference'
}

export type UpdateDependencyFunc = (
  value: any, // The value in the formfield
  getField: (fieldName: string) => any, // function to get a field from the entity
  setLoadings: (fields: any[], status: boolean) => void, // set a loading of a field
  updateFetchedFields: (map: SimpleObject) => void, // function to update 'fetchedFields' of GenericForm
  updateFieldValue: (fieldSelector: string, value: any, isMultiForm?: boolean) => void, // function to update the value from the formfield
  getFieldValue: (fieldSelector: string) => any, // function to get the value from the specified field
  getRow: () => any // function to get the row of an entity field
) => Promise<void>;

export type WebEntityName =
  | 'user'
  | 'userDeliveryAddressDependent'
  | 'userDeliveryAddressProfile'
  | 'userType'
  | 'country'
  | 'brand'
  | 'lot'
  | 'lotItem'
  | 'item'
  | 'shoppingCart'
  | 'cart'
  | 'cartDetail'
  | 'stock'
  | 'availableStock'
  | 'availableStockDependent'
  | 'itemStock'
  | 'element'
  | 'pendingPaymentCartAdmin'
  | 'pendingPaymentCartAdminDependent'
  | 'soldCartAdmin'
  | 'soldCartAdminDependent'
  | 'soldCartProfile'
  | 'soldCartProfileDependent'
  | 'pendingPaymentCartProfile'
  | 'pendingPaymentCartProfileDependent'
  | 'userCartPerUserPageAdmin'
  | 'salesOfAUserCartPerUserPageAdminDependent'
  | 'cartSalesOfAUserCartPerUserPageAdminDependent'
  | 'barcodeSearch'
  | 'discountRule'
  | 'generalSaleRule'
  | 'saleOrder'
  | 'saleOrderDetail'
  | 'shoppingCartItems'
  | 'cartSearch'
  | 'cartSearchDependent'
  | 'priceListEstandar'
  | 'priceListBolsa'
  | 'lotActiveDependent'
  | 'sku'
  | 'stockMovementAudit';

type ConditionalCellStyle = {
  when: (row: any) => boolean;
  style: {
    [key: string]: string | number;
  };
};

export type TableColumn<T> = {
  name: string;
  selector: string;
  wrap?: boolean;
  omit?: boolean;
  center?: boolean;
  sortable?: boolean;
  format?: (entry: T) => string;
  conditionalCellStyles?: ConditionalCellStyle[];
  cell?: (row: T) => JSX.Element;
  allowOverflow?: boolean;
  button?: boolean;
  grow?: number;
  width?: string;
};

export type Fields<T> = {
  name: string;
  selector: string;
  type: FieldTypes;
  textUpperCase?: boolean;
  required?: boolean;
  multiInput?: {
    multiField: boolean;
    isDuplicable: boolean;
    setDefaultValueOnNewRow?: {
      // True, copy the value of this field as default on every new row you add
      previous: boolean; // True, copy previus field. False, copy first field
    };
    sticky: boolean; //sticky column if the form is scrolling
  };
  disabled?: boolean;
  default?: boolean;
  onlyAdmin?: boolean;
  hidden?: boolean;
  defaultValue?: string | Date;
  updateDependency?: UpdateDependencyFunc;
  executeUpdateDependencyOnMount?: boolean;
  timerUpdateDependency?: boolean;
  timezone?: string;
  groupNumber?: number;
  columnClassName?: string;
  reference?: {
    name: string;
    endpoint?: string;
    endpointQuery?: (rowEntity: T) => SimpleObject; // NOTE: returns the object with the data that you want to send to the endpoint
    select: string;
    show: string;
    filter?: { [key: string]: any };
    data?: Array<SimpleObject>;
  };
  constraints?: {
    min: number;
    max: number;
    format: ImageFormats;
  };
};

export type FilterFields<T> = Fields<T>;
type EditableFields<T> = Fields<T>;

export type ExportColumns = {
  name: string;
  selector: string;
  format: 'text' | 'numeric';
  decimalSeparator?: string;
  thousandSeparator?: string;
};

export type CustomButtonNames = {
  addButtonName?: string;
  multiAddButtonName?: string;
  deleteButtonName?: string;
};

export type WebEntity<T> = {
  name: WebEntityName;
  endpoint: string;
  customButtonNames?: CustomButtonNames;
  referenceColumn?: string;
  tableColumns: TableColumn<T>[];
  fields?: Fields<T>[];
  filterFields?: FilterFields<T>[];
  editableFields?: EditableFields<T>[];
  exportColumns?: ExportColumns[];
};

export type WebEntityKeys<T> = keyof WebEntity<T>;
