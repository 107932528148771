import React, { FunctionComponent, ReactNode, useState, CSSProperties, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonProps, Icon } from 'semantic-ui-react';
import {
  //addItemToSaleOrder,
  markShoppingCartAsSold,
  markShoppingCartAsDeclined,
  placeAnOrder,
  //placePreSale,
  lotearItems,
  unlot,
  addNewUserAddress,
  setDefaultDeliveryAddress,
  removeLotsFromCart,
  removeLotDetailFromCart,
  markShoppingCartAsDeclinedSearchCart,
  expireReservedShoppingCart,
  cancelReservedSaleOrder,
  //advancedUnlot,
  //removeLotDetailFromLotAdmin,
  publishStock,
  unpublishStock,
  placePreSaleBasedOnSaleOrder,
  massiveStockOriginEdition
} from '../actions/middleActions';
import Swal from 'sweetalert2';
import GenericModal from './generics/GenericModal';
import { GenericDropDownList, GenericForm } from './generics/GenericForm';
import {
  UserTypeValues,
  User,
  UserDeliveryAddress,
  Cart,
  StateValues,
  SaleOrderDetail,
  Stock
} from '../types';
import { FieldTypes, WebEntity } from '../entities/types';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import { generateBill } from '../utils/GeneratePDF';
import { popAlert, PopAlertType } from './PopAlert';
import { getUncatchEndpointData, performUncatchedRequest } from '../utils/request';
import { areArrayObjectsEqual } from '../utils/utils';

type ButtonParams = {
  action: (params?: any) => Promise<any>;
  selected: any[];
};

type Props = {
  action: (params?: any) => Promise<void>;
  onClick: (buttonParams: ButtonParams) => Promise<void>;
  selected?: any[];
  btnProps: ButtonProps;
  children: ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
};

const SecondaryButton: FunctionComponent<Props> = ({
  action,
  onClick,
  selected = [],
  btnProps,
  children,
  style,
  disabled = false
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick({ action, selected });
    setLoading(false);
  };

  return (
    <>
      <Button
        {...{
          ...{
            key: 'secondary',
            style: { marginRight: '1em', ...(style || {}) },
            color: 'orange',
            icon: false
          },
          ...btnProps,
          onClick: handleOnClick,
          loading,
          disabled
        }}
      >
        {children}
      </Button>
    </>
  );
};

const connectedSecondaryButton = (action: any) => connect(null, { action })(SecondaryButton);

/* const genericOnClick = async (buttonParams: ButtonParams) => {
 *   const { action, selected } = buttonParams;
 *   await action(selected.map((s: any) => s._id));
 * }; */

/* export const exampleButton = ({
 *   selected,
 *   refreshStatus
 * }: {
 *   selected: TypeOfSelected[];
 *   refreshStatus: TypeOfStatus;
 * }) => {
 *   const ConnectedBtn = connectedSecondaryButton(actionToPerform(refreshStatus));
 *
 *   return (
 *     <ConnectedBtn
 *       onClick={genericOnClick}
 *       selected={selected}
 *       btnProps={{ basic: true, color: 'orange', inverted: true }}>
 *       <Icon name='undo' />
 *       Deshacer
 *     </ConnectedBtn>
 *   );
 * }; */

/* DEPRECATED
export const AddItemToSaleOrderButton = (item: SimpleObject) => {
  const ConnectedBtn = connectedSecondaryButton(addItemToSaleOrder);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await Swal.fire({
      title: '',
      text: `Estas seguro de agregar x${item.item.stockQuantity}  ${item.item.itemName} al carrito de compras?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await action(item);
        Swal.fire('Se agrega al carrito de compras');
      } else {
        return;
      }
    });
    return;
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      btnProps={{ basic: false, color: 'blue' }}
      style={{ marginRight: '5px', padding: '0.8em' }}>
      <Icon name='shopping cart' />
    </ConnectedBtn>
  );
};*/

const DeclineShoppingCart: FunctionComponent<{
  shoppingCartId: string;
  cartSearchData?: { shoppingCartId: string; lotId: string };
}> = ({ shoppingCartId, cartSearchData }) => {
  const middleAction = cartSearchData
    ? markShoppingCartAsDeclinedSearchCart
    : markShoppingCartAsDeclined;
  const actionParams = cartSearchData ? cartSearchData : shoppingCartId;

  const ConnectedBtnDecline = connectedSecondaryButton(middleAction);

  const onClickDeclineOrder = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    const result = await Swal.fire({
      title: '',
      text: `¿Estas seguro de que deseas rechazar el pedido ${shoppingCartId}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    });

    if (result.isConfirmed) {
      await action(actionParams);
    } else {
      return;
    }
  };
  return (
    <ConnectedBtnDecline
      onClick={onClickDeclineOrder}
      btnProps={{ basic: false, color: 'red' }}
      style={{ width: '7.6em' }}
    >
      Cancelar Pedido
    </ConnectedBtnDecline>
  );
};

const ShoppingCartModalForm: FunctionComponent<{
  markShoppingCartAsSold: any;
  shoppingCartId: string;
}> = ({ markShoppingCartAsSold, shoppingCartId }) => {
  const onSubmit = async (e: Event, formFields: any) => {
    e.preventDefault();
    // TODO: Look for a more elegant way to close the modal (now it close just beacuse the table reload)
    const shopingCartData: Array<{ documentNumber: string; shoppingCartId: string }> = [
      { documentNumber: formFields.documentNumber, shoppingCartId }
    ];

    const res = await markShoppingCartAsSold(shopingCartData);

    if (res) {
      Swal.fire(`Se ha confirmado el pago del pedido ${shoppingCartId}.`);
    }
  };

  return (
    <div style={{ padding: '0.5em' }}>
      <GenericModal buttonName={'Confirmar'} btnColor={'green'} style={{ width: '7.6em' }}>
        <GenericForm
          {...{
            fields: [
              {
                name: 'Numero de Factura',
                selector: 'documentNumber',
                type: FieldTypes.Text,
                required: true
              }
            ],
            onSubmit,
            actionName: 'Confirmar Pago',
            refetchFieldsOnSubmit: false
          }}
        />
      </GenericModal>
    </div>
  );
};

const ConnectedShoppingCartModalForm = connect(({ auth }: AppState) => ({ auth }), {
  markShoppingCartAsSold
})(ShoppingCartModalForm);

export const PendingPaymentCartModalActions: FunctionComponent<{
  shoppingCartId: string;
}> = ({ shoppingCartId }) => {
  return (
    <div style={{ padding: '0.5em' }}>
      <GenericModal
        title={'Acción a realizar'}
        buttonName={'Acción'}
        btnColor={'green'}
        style={{ width: '7.6em' }}
      >
        <div style={{ display: 'flex' }}>
          <ConnectedShoppingCartModalForm shoppingCartId={shoppingCartId} />
          <DeclineShoppingCart shoppingCartId={shoppingCartId} />
          <PurchaseDataPDFButton cartId={shoppingCartId} />
        </div>
      </GenericModal>
    </div>
  );
};

export const OrderToCompleteModalActions: FunctionComponent<{
  pageSwitcher: (orderId: string) => () => void;
  saleOrderId: string;
}> = ({ pageSwitcher, saleOrderId }) => {
  const ConnectedBtn = connectedSecondaryButton(cancelReservedSaleOrder);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    Swal.fire({
      icon: 'question',
      title: `¿Está seguro que desea cancelar la orden?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await action(saleOrderId);
      }
    });
  };

  return (
    <div style={{ padding: '0.5em' }}>
      <GenericModal
        title={'Acción a realizar'}
        buttonName={'Acción'}
        btnColor={'green'}
        style={{ width: '7.6em' }}
      >
        <div style={{ display: 'flex' }}>
          <Button color='green' onClick={pageSwitcher(saleOrderId)}>
            Completar Orden
          </Button>
          <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'red' }}>
            <Icon name='warning' />
            Anular
          </ConnectedBtn>
        </div>
      </GenericModal>
    </div>
  );
};

export const AddBuyerToOrderButton = ({
  disabled,
  setBuyerUser
}: {
  disabled: boolean;
  setBuyerUser: React.Dispatch<React.SetStateAction<User | null>>;
}) => {
  const onSubmit = async (e: Event, formFields: any) => {
    e.preventDefault();

    try {
      const { nationalId } = formFields;

      const getBuyerUserData = await getUncatchEndpointData({
        endpoint: 'users/searchUser',
        query: { nationalId }
      });

      if (getBuyerUserData.length > 0) {
        setBuyerUser(getBuyerUserData[0]);
        Swal.fire({
          icon: 'success',
          title: '¡Se ha encontrado un comprador!',
          text: `Encontramos al Usuario ${getBuyerUserData[0].userFullname} para el RUT ${nationalId}`
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: (error as any).response?.data?.msg || (error as any).response?.statusText || '',
        text: (error as any).response?.data?.details
      });
    }
  };

  return (
    <GenericModal
      buttonName={'Agregar Comprador'}
      buttonIcon='user plus'
      disabled={disabled}
      btnColor={'brown'}
    >
      <GenericForm
        {...{
          fields: [
            {
              name: 'Usuario',
              selector: 'nationalId',
              type: FieldTypes.Reference,
              required: true,
              reference: {
                name: 'user',
                endpoint: 'users',
                select: 'nationalId',
                show: 'userFullname, nationalId',
                filter: {
                  userTypeNames: [UserTypeValues.Cliente]
                }
              }
            }
          ],
          onSubmit,
          actionName: `Buscar Rut`,
          refetchFieldsOnSubmit: false
        }}
      />
    </GenericModal>
  );
};

export const PlaceAnOrderButton = ({
  resumeSaleOrder,
  disabled,
  refreshSaleOrderDetail,
  clientSaleOrderDetailItems
}: {
  resumeSaleOrder: {
    isDispatch: boolean;
    deliveryAddressId?: string;
    saleOrderDate: string;
    buyerUserId: string;
  };
  disabled: boolean;
  refreshSaleOrderDetail: () => Promise<void>;
  clientSaleOrderDetailItems: SaleOrderDetail[];
}) => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const ConnectedBtn = connectedSecondaryButton(placeAnOrder);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    try {
      setIsloading(true);
      const realSaleOrderDetailItems = await getUncatchEndpointData({
        endpoint: 'trades/saleOrderItems'
      });

      if (!areArrayObjectsEqual(realSaleOrderDetailItems, clientSaleOrderDetailItems)) {
        Swal.fire({
          title: 'No se ha podido completar el pedido',
          text: 'El carrito ha sufrido cambios, por favor actualice',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
        refreshSaleOrderDetail();
        setIsloading(false);
        return;
      }

      const sucess = await action(resumeSaleOrder);

      if (sucess) {
        Swal.fire({
          title: 'Reserva realizada!',
          text: 'Las cajas finales quedan pendientes con Bodega',
          icon: 'success',
          showCloseButton: true
        });
      }
      setIsloading(false);
    } catch (error) {
      console.error('Error in action:', error);
      setIsloading(false);
    }
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      btnProps={{ basic: false, color: 'green' }}
      disabled={disabled || isLoading}
    >
      <Icon name='shopping basket' />
      Realizar Pedido
    </ConnectedBtn>
  );
};

// DEPRECATED

/* export const PreSaleButton = ({
 *   resumeOrder,
 *   disabled,
 *   auth,
 *   buyerUser,
 *   setBuyerUser,
 *   clientShoppingCartItemsList,
 *   refreshShoppingCart
 * }: {
 *   resumeOrder: {
 *     isDispatch: boolean;
 *     deliveryAddressId?: string;
 *     cartOrderDate: string;
 *     buyerUserId: string;
 *   };
 *   disabled: boolean;
 *   auth: AuthState;
 *   buyerUser: User | null;
 *   setBuyerUser: (user: User) => void;
 *   clientShoppingCartItemsList: LotItem[];
 *   refreshShoppingCart: () => Promise<void>;
 * }) => {
 *   const [isLoading, setIsloading] = useState<boolean>(false);
 *
 *   const ConnectedBtn = connectedSecondaryButton(placePreSale);
 *   const onClick = async (buttonParams: ButtonParams) => {
 *     const { action } = buttonParams;
 *     try {
 *       setIsloading(true);
 *       const realShoppingCartItems = (await getUncatchEndpointData({
 *         endpoint: 'trades/shoppingCartItems'
 *       })) as LotItem[];
 *       if (areArrayObjectsEqual(realShoppingCartItems, clientShoppingCartItemsList)) {
 *         const res = await action(resumeOrder);
 *         if (res && res.data) {
 *           Swal.fire({
 *             title: 'Reserva realizada!',
 *             text: 'La reserva se ha realizado con éxito, para más detalles sobre su reserva y detalles de transferencia dirígase a la página de Perfil',
 *             icon: 'success',
 *             showCloseButton: true
 *           });
 *           await generateBill(res.data);
 *         }
 *         setIsloading(false);
 *         return;
 *       }
 *
 *       Swal.fire({
 *         title: 'No se ha podido completar el pedido',
 *         text: 'El carrito ha sufrido cambios, por favor actualice',
 *         icon: 'warning',
 *         confirmButtonText: 'Ok'
 *       });
 *       refreshShoppingCart();
 *
 *       setIsloading(false);
 *     } catch (error) {
 *       console.error('Error in action:', error);
 *       setIsloading(false);
 *     }
 *   };
 *
 *   const fieldsForm = [
 *     {
 *       name: 'Rut',
 *       selector: 'nationalId',
 *       type: FieldTypes.Rut,
 *       required: true
 *     }
 *   ];
 *
 *   const onSubmit = async (e: Event, formFields: any) => {
 *     e.preventDefault();
 *
 *     try {
 *       const { nationalId } = formFields;
 *
 *       const getBuyerUserData = await getUncatchEndpointData({
 *         endpoint: 'users/searchUser',
 *         query: { nationalId }
 *       });
 *
 *       if (getBuyerUserData.length > 0) {
 *         setBuyerUser(getBuyerUserData[0]);
 *         Swal.fire({
 *           icon: 'success',
 *           title: '¡Se ha encontrado un comprador!',
 *           text: `Encontramos al Usuario ${getBuyerUserData[0].userFullname} para el RUT ${nationalId}`
 *         });
 *       }
 *     } catch (error) {
 *       Swal.fire({
 *         icon: 'error',
 *         title: (error as any).response?.data?.msg || (error as any).response?.statusText || '',
 *         text: (error as any).response?.data?.details
 *       });
 *     }
 *   };
 *
 *   return auth.user?.userTypeName === UserTypeValues.Cliente || buyerUser ? (
 *     <ConnectedBtn
 *       onClick={onClick}
 *       btnProps={{ basic: false, color: 'green' }}
 *       disabled={disabled || isLoading}
 *     >
 *       <Icon name='shopping basket' />
 *       Realizar Pedido
 *     </ConnectedBtn>
 *   ) : (
 *     <GenericModal
 *       buttonName={'Agregar Comprador'}
 *       buttonIcon='user plus'
 *       disabled={disabled}
 *       btnColor={'brown'}
 *     >
 *       <GenericForm
 *         {...{
 *           fields: fieldsForm,
 *           onSubmit,
 *           actionName: `Buscar Rut`,
 *           refetchFieldsOnSubmit: false
 *         }}
 *       />
 *     </GenericModal>
 *   );
 * }; */

export const LotearItemsButton = ({ itemIds }: { itemIds: Array<string> }) => {
  const ConnectedBtn = connectedSecondaryButton(lotearItems);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const res = await action(itemIds);
    if (!res || res.status !== 200) {
      return;
    }
    let barcodes = [];
    if (res.data.stock.length > 0) {
      barcodes = res.data.stock.map((el: Stock) => el.barcode);
    }
    if (res && res.data && res.data.lotId && barcodes.length > 0) {
      Swal.fire({
        icon: 'success',
        title: `<h3>El numero de lote es: ${res.data.lotId}\n
        Se lotearon los siguientes Barcodes (${barcodes.length}):</h3>
        `,
        text: `${barcodes.join(', ')}`,
        showCloseButton: true
      });
    }
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'green' }}>
      <Icon name='boxes' />
      Lotear Items
    </ConnectedBtn>
  );
};

export const PublishStockButton = ({ stockIds }: { stockIds: Array<string> }) => {
  const ConnectedBtn = connectedSecondaryButton(publishStock);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const res = await action(stockIds);
    if (res && res.status === 200) {
      if (res.data && res.data.length > 0) {
        const barcodes = res.data.map((s: Stock) => s.barcode);
        Swal.fire({
          icon: 'success',
          title: `<h3>El stock seleccionado se ha publicado exitosamente!</h3>`,
          text: `${barcodes.join(', ')}`,
          showCloseButton: true
        });
      }
    }
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'green' }}>
      <Icon name='boxes' />
      Publicar Stock
    </ConnectedBtn>
  );
};

export const MassiveStockOriginEditionButton = ({
  stockIds,
  stockOriginNameToFetch
}: {
  stockIds: Array<string>;
  stockOriginNameToFetch?: String;
}) => {
  const ConnectedBtn = connectedSecondaryButton(massiveStockOriginEdition);

  const [stockOriginId, setStockOriginId] = useState('');
  const [stockOrigins, setStockOrigins] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStockOrigin = async () => {
      setLoading(true);
      try {
        const { data } = await performUncatchedRequest({
          endpoint: `stock/stockOrigin`,
          method: 'get'
        });

        setStockOrigins(data);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    fetchStockOrigin();
  }, []);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (!stockOriginId) {
      Swal.fire({
        icon: 'error',
        title: `<h3>Debe seleccionar el origen al cual desea movel el stock</h3>`,
        showCloseButton: true
      });
      return;
    }

    const res = await action({ stockIds, stockOriginId, stockOriginNameToFetch });
    if (res && res.status === 200) {
      if (res.data && res.data.length > 0) {
        const barcodes = res.data.map((s: Stock) => s.barcode);
        Swal.fire({
          icon: 'success',
          title: `<h3>El stock seleccionado se ha movido exitosamente!</h3>`,
          text: `${barcodes.join(', ')}`,
          showCloseButton: true
        });
      }
    }
  };

  const stockOriginOnChange = (_: any, data: any) => {
    setStockOriginId(data.value);
  };

  return (
    <div>
      <GenericModal
        title='Editar Origen Stock'
        buttonName=' Editar Origen Stock'
        buttonIcon='dolly'
      >
        <div>
          <div>
            <GenericDropDownList
              {...{
                fieldName: 'Origen Stock',
                data: stockOrigins,
                valueField: 'stockOriginId',
                textField: 'stockOriginName',
                onChange: stockOriginOnChange,
                value: stockOriginId,
                required: true,
                loading: loading
              }}
            />
          </div>
          <div>
            <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'green' }}>
              <Icon name='dolly' />
              Mover Stock
            </ConnectedBtn>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};

export const UnpublishStockButton = ({
  stockIds,
  itemId,
  stockOriginName
}: {
  stockIds: Array<string>;
  itemId: string;
  stockOriginName: string;
}) => {
  const ConnectedBtn = connectedSecondaryButton(unpublishStock);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const res = await action({ stockIds, itemId, stockOriginName });
    if (res && res.status === 200) {
      if (res.data && res.data.deletedEntity && res.data.deletedEntity.length > 0) {
        Swal.fire({
          icon: 'success',
          title: `<h3>El stock seleccionado se ha despublicado exitosamente!</h3>`,
          text: `${res.data.deletedEntity.join(', ')}`,
          showCloseButton: true
        });
      }
    }
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'red' }}>
      <Icon name='boxes' />
      Despublicar
    </ConnectedBtn>
  );
};

export const UnlotButton = ({ lotIds }: { lotIds: Array<string> }) => {
  const ConnectedBtn = connectedSecondaryButton(unlot);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    Swal.fire({
      icon: 'question',
      title: `¿Está seguro que desea deshacer los siguientes lotes?`,
      text: `${lotIds}`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await action(lotIds);
        if (!res || res.status !== 200) {
          return;
        }
        Swal.fire({ title: 'Se han desloteado los siguientes lotes:', text: `${lotIds}` });
      }
    });
    return;
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'red' }}>
      <Icon name='undo' />
      Deslotear
    </ConnectedBtn>
  );
};

const NewAddressForm: FunctionComponent<{
  addNewUserAddress: any;
  auth: AuthState;
  user: User;
  requestFrom: 'Profile' | 'Maintainer';
  btnName?: string;
  forceDefaultAddress?: boolean;
  callback?: () => void;
  disabled?: boolean;
}> = ({
  addNewUserAddress,
  auth,
  user,
  requestFrom,
  btnName,
  forceDefaultAddress,
  callback,
  disabled
}) => {
  const fieldsForm = [
    {
      name: 'Dirección',
      selector: 'deliveryAddress',
      type: FieldTypes.Text,
      required: true
    }
  ];

  if (!forceDefaultAddress) {
    fieldsForm.push({
      name: 'Por defecto?',
      selector: 'isDefaultDeliveryAddress',
      type: FieldTypes.Boolean,
      required: true
    });
  }

  const onSubmit = async (e: Event, formFields: any) => {
    e.preventDefault();

    const isDefaultDeliveryAddress = forceDefaultAddress
      ? true
      : formFields.isDefaultDeliveryAddress
      ? formFields.isDefaultDeliveryAddress
      : false;

    const newUserAddressData = {
      userId: user._id,
      deliveryAddress: formFields.deliveryAddress,
      isDefaultDeliveryAddress
    };
    const res = await addNewUserAddress(newUserAddressData, requestFrom);

    if (res) {
      Swal.fire(`La dirección se ha agregado correctamente`);
      if (callback) {
        callback();
      }
    }
  };

  return (
    <GenericModal buttonName={btnName || '+'} buttonIcon='address book' disabled={disabled}>
      <GenericForm
        {...{
          fields: fieldsForm,
          onSubmit,
          isAdmin: auth.user?.userTypeName === UserTypeValues.Admin,
          actionName: `Agregar Dirección para ${user.userUsername}`,
          refetchFieldsOnSubmit: false
        }}
      />
    </GenericModal>
  );
};
export const ConnectedNewAddressForm = connect(({ auth }: AppState) => ({ auth }), {
  addNewUserAddress
})(NewAddressForm);

export const SetDefaultDeliveryAddressBtn = ({
  entity,
  selected
}: {
  entity: WebEntity<any>;
  selected: Array<UserDeliveryAddress>;
}) => {
  const userDeliveryAddressId = selected.map((item) => item._id);
  const ConnectedBtn = connectedSecondaryButton(setDefaultDeliveryAddress);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (userDeliveryAddressId.length > 1) {
      popAlert({
        type: PopAlertType.ERROR,
        title: `Accion no permitida`,
        details: `No se puede asignar mas de una dirección por defecto`
      });
      return;
    }

    const deliveryAddressData = {
      entity,
      userId: selected[0].userId,
      userDeliveryAddressId: selected[0].userDeliveryAddressId
    };

    await action(deliveryAddressData);
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      btnProps={{ basic: false }}
      style={{
        borderRadius: '9px',
        padding: '10px 20px',
        color: '#fff',
        fontWeight: 500,
        textTransform: 'uppercase',
        backgroundColor: '#0275d8'
      }}
    >
      Elegir por Defecto
    </ConnectedBtn>
  );
};

export const SaleCompletionButton = ({ saleOrderId }: { saleOrderId: string }) => {
  const ConnectedBtn = connectedSecondaryButton(placePreSaleBasedOnSaleOrder);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action(saleOrderId);
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      btnProps={{ basic: false }}
      style={{
        borderRadius: '9px',
        padding: '10px 20px',
        color: '#fff',
        fontWeight: 500,
        textTransform: 'uppercase',
        backgroundColor: '#0275d8'
      }}
    >
      Completar Pedido
    </ConnectedBtn>
  );
};

export const RemoveLotsFromCartButton = ({ itemIds }: { itemIds: Array<string> }) => {
  const ConnectedBtn = connectedSecondaryButton(removeLotsFromCart);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action(itemIds);
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'red' }}>
      <Icon name='trash alternate' />
      Quitar lote del carro
    </ConnectedBtn>
  );
};

export const RemoveLotDetailsFromCartButton = ({
  cartDetailIds
}: {
  cartDetailIds: Array<string>;
}) => {
  const ConnectedBtn = connectedSecondaryButton(removeLotDetailFromCart);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action(cartDetailIds);
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'red' }}>
      <Icon name='trash alternate' />
      Quitar del carro
    </ConnectedBtn>
  );
};

export const PurchaseDataPDFButton = ({ cartId }: { cartId: string }) => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const onClick = async () => {
    setIsloading(true);
    try {
      const res = await getUncatchEndpointData({
        endpoint: 'carts/purchase-data/pdf-data',
        query: { cartId }
      });
      await generateBill(res);

      setIsloading(false);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: (error as any).response?.data?.msg || (error as any).response?.statusText || '',
        text: (error as any).response?.data?.details || error
      });
      setIsloading(false);
    }
  };

  return (
    <Button onClick={onClick} color='red' loading={isLoading}>
      <Icon name='file pdf' />
      PDF
    </Button>
  );
};

const ExpireShoppingCartReservation: FunctionComponent<{
  cartSearchData: { shoppingCartId: string; lotId: string };
}> = ({ cartSearchData }) => {
  const ConnectedBtnDecline = connectedSecondaryButton(expireReservedShoppingCart);

  const onClickDeclineOrder = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    const result = await Swal.fire({
      title: '',
      text: `¿Estas seguro de que deseas eliminar la reserva ${cartSearchData.shoppingCartId}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    });

    if (result.isConfirmed) {
      await action(cartSearchData);
    }
  };
  return (
    <ConnectedBtnDecline
      onClick={onClickDeclineOrder}
      btnProps={{ basic: false, color: 'red' }}
      style={{ width: '7.6em' }}
    >
      Expirar Reserva
    </ConnectedBtnDecline>
  );
};

export const CartSearchActions: FunctionComponent<{
  shoppingCart: Cart;
}> = ({ shoppingCart }) => {
  const cartSearchData = {
    shoppingCartId: shoppingCart._id,
    lotId: shoppingCart.lotId!
  };
  return shoppingCart.stateName === StateValues.PendientePago ? (
    <DeclineShoppingCart shoppingCartId={shoppingCart._id} cartSearchData={cartSearchData} />
  ) : shoppingCart.stateName === StateValues.Vendido ? (
    <h1>Vendido</h1>
  ) : shoppingCart.stateName === StateValues.Activo ? (
    <ExpireShoppingCartReservation cartSearchData={cartSearchData} />
  ) : (
    <>{shoppingCart.stateName}</>
  );
};

/*export const AdvancedUnlotButton: FunctionComponent<{
  lotId: string;
}> = ({ lotId }) => {
  const ConnectedBtnDecline = connectedSecondaryButton(advancedUnlot);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (!lotId || lotId === '') {
      Swal.fire({ icon: 'warning', title: 'Debe ingresar un numero de lote para deslotear' });
      return;
    }

    Swal.fire({
      icon: 'question',
      title: `¿Está seguro que desea deshacer el lote ${lotId}?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await action(lotId);

        if (res.status !== 200) {
          return;
        }

        const barcodes = res.data.barcodes.map((barcode: string) => barcode.replace(/'/g, ''));
        Swal.fire({
          title: `Se ha desloteado el lote ${lotId} y los siguientes barcode han vuelto al Ingreso y loteo de Stock:`,
          text: `${barcodes}`
        });
      }
    });
    return;
  };
  return (
    <ConnectedBtnDecline
      onClick={onClick}
      btnProps={{ basic: false, color: 'red' }}
      style={{ width: '7.6em' }}>
      Deslotear
    </ConnectedBtnDecline>
  );
}; 

export const RemoveLotDetailFromLotAdmin = ({ lotDetails }: { lotDetails: LotDetail[] }) => {
  const ConnectedBtn = connectedSecondaryButton(removeLotDetailFromLotAdmin);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    const lotId = lotDetails[0].lotId;
    const lotDetailIds = lotDetails.map((lotDetail) => lotDetail.lotDetailId);
    const data = { lotId, lotDetailIds };
    await Swal.fire({
      title: `¿Estás seguro de quitar los items (${lotDetailIds}) del lote ${lotId}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await action(data);
        if (res.status !== 200) {
          return;
        }
        const barcodes = res.data.barcodes.map((barcode: string) => barcode.replace(/'/g, ''));
        Swal.fire({
          title: `Los siguientes Barcode se han quitado del lote ${lotId} y han vuelto al Ingreso y loteo de Stock:`,
          text: `${barcodes}`
        });
      }
    });
  };

  return (
    <ConnectedBtn onClick={onClick} btnProps={{ basic: false, color: 'red' }}>
      <Icon name='trash alternate' />
      Quitar del Lote
    </ConnectedBtn>
  );
};*/
