import { SaleOrderDetail } from '../types';
import { formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

/*
item_count.item_id AS _id,
item_count.item_id,
item_count.item_name,
item_count.unit_of_measure_price,
item_count.unit_of_measure_quantity_avg,
item_count.stock_quantity - COALESCE(sale_order_detail.item_quantity,0) AS stock_quantity,
brand.brand_name,
country.country_name,
packaging.packaging_name,
unit_of_measure.unit_of_measure_name,
item_count.date_created
*/

const SaleOrderDetailEntity: WebEntity<SaleOrderDetail> = {
  name: 'saleOrderDetail',
  endpoint: 'trades/saleOrderItems',
  referenceColumn: '_id',
  customButtonNames: {
    deleteButtonName: 'Quitar del carro'
  },

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Unidades en carro / Cantidad disponible',
      selector: 'packagingLotCurrentQuantity',
      format: ({ totalItemQuantity, itemQuantityInSaleOrder, packagingName }) =>
        `${packagingName}s: ${itemQuantityInSaleOrder} de ${totalItemQuantity || 0}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Producto',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Pais Origen',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Empaque',
      selector: 'packagingName',
      format: ({ packagingName }) => `${packagingName}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Promedio por empaque',
      selector: 'unitOfMeasureQuantityAvg',
      format: ({ unitOfMeasureQuantityAvg }) =>
        `${formatStringNumber(unitOfMeasureQuantityAvg.toString(), true, 2, 2)}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad de Medida',
      selector: 'unitOfMeasureName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Valor Kg',
      selector: 'unitOfMeasurePrice',
      format: ({ unitOfMeasurePrice }) =>
        `$${formatStringNumber(unitOfMeasurePrice.toString(), false)}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Subtotal Estimado',
      selector: 'unitOfMeasurePrice',
      format: ({ unitOfMeasurePrice, unitOfMeasureQuantityAvg, itemQuantityInSaleOrder }) =>
        `$${formatStringNumber(
          Math.round(
            Number(unitOfMeasurePrice) *
              Number(unitOfMeasureQuantityAvg) *
              Number(itemQuantityInSaleOrder)
          ).toString(),
          false
        )}`,
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Precio',
      selector: 'unitOfMeasurePrice',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Item',
      selector: 'itemName',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Narca',
      selector: 'brandName',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Pais',
      selector: 'countryName',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Empaque',
      selector: 'packagingName',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Promedio por empaque',
      selector: 'unitOfMeasureQuantityAvg',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'unidad de medida',
      selector: 'unitOfMeasureName',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Stock Origen Name',
      selector: 'stockOriginName',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'cantidad total',
      selector: 'totalItemQuantity',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Cantidad en orden',
      selector: 'itemQuantityInSaleOrder',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Precio',
      selector: 'unitOfMeasurePrice',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default SaleOrderDetailEntity;
